import React from "react";

const rigImage = require("../assets/rig.jpg");

export const Welcome = () => {
  return (
    <div className="body-width flex sm:flex-row flex-col justify-center h-screen text-white p-5 pt-[65px]">
      <div className="md:w-[45%] flex flex-col gap-5">
        <img
          src={rigImage}
          className="w-full sm:p-10 sm:h-full h-[200px] object-cover rounded-2xl"
          alt="Rig"
        />
      </div>
      <div className="md:w-1/2 flex flex-wrap h-full sm:text-left text-center gap-3 flex-col sm:justify-center p-5 ">
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          className="sm:text-xl text-sm"
        >
          WELCOME TO GAS-ZONE INTERNATIONAL LIMITED
        </p>
        <p
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="200"
          className="sm:text-6xl text-2xl"
        >
          We are driving changes through sustainable energy
        </p>
        <p
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="400"
          className=" sm:text-xl text-sm"
        >
          Gas-Zone is at the forefront of Africa’s energy sector transformation.
          With a commitment to sustainability and innovation, we provide expert
          solutions in energy advisory, trading, and infrastructure development.
        </p>
      </div>
    </div>
  );
};
