import { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Navbar } from "./components";
import { AboutUs, ContactUs, Home, Services, Welcome } from "./screens";

function App() {
  const refs = {
    home: useRef(null),
    about: useRef(null),
    contactus: useRef(null),
    ourservices: useRef(null),
  };
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <main style={{ minHeight: "100%", minWidth: "100%" }}>
        <div className="fixed w-full z-10 ">
          <Navbar refs={refs} />
        </div>

        <div className="sm:text-4xl text-2xl font-bold text-left bg-primary">
          {/* <Home /> */}
          <Welcome ref={refs.home} />
          <AboutUs ref={refs.about} />
          <Services ref={refs.ourservices} />
          <ContactUs ref={refs.contactus} />
        </div>
      </main>
    </div>
  );
}

export default App;
