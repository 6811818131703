import React, { forwardRef } from "react";
import { ReactComponent as Email } from "../assets/svg/Email.svg";
import { ReactComponent as Location } from "../assets/svg/Location.svg";

export const ContactUs = forwardRef((props, ref) => {
  const Map = () => (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1556.5649136044478!2d7.49575277871496!3d9.0213108262054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0c76ebde0717%3A0x57828b29f9d837ab!2s12%20Oke-Agbe%20Cl%2C%20Garki%2C%20Abuja%20900110%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1723375182234!5m2!1sen!2sng"
      width="600"
      height="450"
      style={{ border: 0, height: 350, width: "100%" }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    />
  );

  return (
    // <div className="flex flex-col place-items-baseline h-[calc(100vh-65px)] bg-white md:p-10 gap-5">
    //     <div className="sm:p-20 p-5">
    //       <p className="text-primary md:text-8xl text-2xl md:py-10 py-5">
    //         Contact Us
    //       </p>
    //       <h2 className="text-primary">Nigeria</h2>

    //       <div className="gap-10">
    //         <p className="md:text-2xl text-sm">
    //           12 Oke-Agbe close Garki, Abuja, Nigeria
    //         </p>
    //         <p className="md:text-2xl text-sm text-wrap">
    //           Email:
    //           <a
    //             className="md:text-2xl text-sm"
    //             href="mailto:Gaszoneinternationalltd@gmail.com"
    //           >
    //             Gaszoneinternationalltd@gmail.com
    //           </a>
    //           ,<br />
    //           <a
    //             className="md:text-2xl text-sm"
    //             href="mailto:info@gas-zone.com"
    //           >
    //             info@gas-zone.com
    //           </a>
    //         </p>
    //         <a className="md:text-2xl text-sm" href="phoneto:07067767051">
    //           TELL: 07067767051
    //         </a>
    //       </div>
    //     </div>

    //   <p className="underline sm:text-4xl text-2xl text-primary md:text-left text-center ">
    //     Strategic Partners
    //   </p>

    //   {/* <div className="flex md:flex-row w-full md:justify-between place-items-center gap-5 flex-wrap"> */}
    //   <div className="grid md:grid-cols-4 grid-cols-2 gap-4 place-items-center">
    //     <a
    //       href="https://transoceanic.group/"
    //       target="_blank"
    //       className="flex place-items-center"
    //     >
    //       <img
    //         src={require("../assets/logo-2-white.png")}
    //         className="sm:w-20 w-12  top-5 left-5"
    //       />
    //       <div className="text-blue">
    //         <p className="font-bold md:text-xl text-sm"> TRANSOCEANIC</p>
    //         <p className="md:text-xl text-sm">Energy Group</p>
    //       </div>
    //     </a>
    //     <a
    //       href="https://dexterprolimited.com/"
    //       target="_blank"
    //       className="font-bold md:text-xl text-sm"
    //     >
    //       DEXTERPRO
    //     </a>
    //     <a
    //       href="https://kyjegeodrillsl.com/"
    //       target="_blank"
    //       className="font-bold md:text-xl text-sm text-limeGreen"
    //     >
    //       KYJEGEODRILS
    //     </a>
    //     <a
    //       href="https://energy-vg.com/"
    //       target="_blank"
    //       className="font-bold md:text-xl text-sm text-brown"
    //     >
    //       VGENERGY
    //     </a>
    //   </div>
    // </div>
    <div
      ref={ref}
      className="flex flex-col place-items-baseline h-[calc(100vh-65px)] bg-white md:p-10 gap-5"
    >
      <p className="text-primary md:text-3xl text-2xl py-5">Contact Us</p>
      <div className="grid md:grid-cols-2 grid-cols-1 w-full h-full">
        <div className=""></div>
        <div className=" gap-10 justify-between">
          <div className="flex pb-10">
            <div className="w-1/2 border-l-[0.5px] border-grey pl-5 gap-5">
              <Email style={{ height: 40 }} />
              <br />
              <div className="text-grey">
                <p>
                  <a href="mailto:info@gas-zone.com">info@gas-zone.com</a>
                </p>
                <a href="phoneto:07067767051">(+234)7067767051</a>
              </div>
            </div>
            <div className="w-1/2 border-l-[0.5px] border-grey pl-5 gap-5 ">
              <Location style={{ height: 40 }} />
              <br />
              <div className="text-grey">
                <p>12 Oke-Agbe close, Area 11, Garki</p>
                <p>Abuja, Nigeria</p>
              </div>
            </div>
          </div>
          <Map />
        </div>
      </div>
    </div>
  );
});
