import React, { useEffect, useState } from "react";

export const Navbar = ({ refs = {} }) => {
  const [active, setActive] = useState("Home");
  const screens = ["Home", "About", "Our Services", "Contact Us"];

  const handleClick = (screen) => {
    const offsetTop =
      refs[screen.toLowerCase().split(" ").join("")].current?.offsetTop - 60;
    window.scrollTo({ behavior: "smooth", top: offsetTop });
    setActive(screen);
  };

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  return (
    <div className="flex justify-between place-items-center bg-primary border-b-2 text-white p-2">
      <div>
        <img
          src={require("../assets/logo.png")}
          className="sm:w-[50px] w-10 "
        />
      </div>
      <nav className="sm:flex hidden gap-5 px-5 list-none text-xl">
        {screens.map((screen) => (
          <li
            className={`${active == screen ? "font-bold" : ""}`}
            onClick={() => handleClick(screen)}
          >
            {screen}
          </li>
        ))}
      </nav>

      <div className="sm:hidden flex flex-col gap-1 mr-2">
        <div className="hamburger-line" />
        <div className="hamburger-line" />
        <div className="hamburger-line" />
      </div>
    </div>
  );
};
