import React, { forwardRef } from "react";

const rigImage = require("../assets/rig.jpg");

export const AboutUs = forwardRef((props, ref) => {
  const cardStyle =
    "bg-primary p-5 text-white text-justify gap-5 rounded text-xl";
  const cardTitle = "sm:text-xl text-md font-bold mb-5 text-center";
  return (
    <div
      ref={ref}
      className="body-width min-h-[calc(100vh-65px)] bg-white sm:h-cropped p-10 gap-10"
    >
      <p
        data-aos="fade-right"
        data-aos-duration="1000"
        className="sm:text-2xl text-sm font-bold mb-10 text-center"
      >
        ABOUT US
      </p>
      <div className="grid md:grid-cols-3 grid-cols-1 justify-center place-items-center gap-5">
        <div className={cardStyle}>
          <p className={cardTitle}>WHO WE ARE</p>
          <p
            data-aos="fade-right"
            data-aos-duration="1000"
            // data-aos-delay="200"
          >
            Gas-Zone stands at the vanguard of Africa’s energy sector
            transformation, championing sustainability and innovation across the
            continent. Our mission is to deliver unparalleled solutions in
            energy advisory, trading, and infrastructure development, driven by
            a commitment to excellence and value for our clients and pa ners.
          </p>
          <br />
          <p
            data-aos="fade-right"
            data-aos-duration="1000"
            // data-aos-delay="400"
          >
            At Gas-Zone, our team of seasoned professionals brings a wealth of
            experience and deep industry knowledge to the table. We pride
            ourselves on our ability to provide expe  guidance and suppo  across
            the entire energy value chain. Our services are designed to be
            comprehensive and tailored, ensuring that each client receives the
            precise assistance they need to meet their unique objectives and
            requirements.
          </p>
        </div>
        <div className={cardStyle}>
          <p className={cardTitle}>
            OUR COMMITMENT TO SUSTAINABILITY AND INNOVATION
          </p>
          <p data-aos="fade-left" data-aos-duration="1000">
            Sustainability is at the core of our operations. We are constantly
            seeking new oppo unities and pa nerships that align with our vision of
            a sustainable future for Africa’s energy sector. Our innovative
            approach allows us to stay ahead of industry trends, ensuring that
            our solutions are not only relevant but also transformative.
          </p>
          <br />
          <p data-aos="fade-left" data-aos-duration="1000">
            By leveraging our extensive industry knowledge and strategic
            insights, we are able to create impac ul solutions that address the
            dynamic needs of our clients and the broader market. Our commitment
            to sustainability and innovation drives us to continually explore
            new avenues for growth and development, fostering a resilient and
            forward-thinking energy industry in Africa.
          </p>
          <br />
          <p data-aos="fade-left" data-aos-duration="1000">
            We are actively involved in research and development, investing in
            new technologies and methodologies that promote clean energy and
            reduce environmental impact. Our initiatives include exploring
            renewable energy sources, enhancing energy e ciency, and developing
            sma  grid technologies. By staying at the forefront of innovation, we
            aim to set new benchmarks in the energy sector and lead the way
            towards a greener future.
          </p>
        </div>
        <div className={cardStyle}>
          <p className={cardTitle}>OUR VISION FOR THE FUTURE</p>

          <p data-aos="fade-right" data-aos-duration="1000">
            At Gas-zone, we aspire to be a trusted pa ner and a driving force in
            shaping the future of Africa’s energy landscape. Our dedication to
            delivering excellence and value positions us as a leader in the
            industry, commi ed to making a lasting impact. We believe that
            through collaboration, innovation, and unwavering commitment to
            sustainability, we can help build a brighter, more sustainable
            future for all.
          </p>
          <br />
          <p data-aos="fade-right" data-aos-duration="1000">
            Join us on this transformative journey as we pave the way for a new
            era of energy in Africa. Together, we can create solutions that not
            only meet the challenges of today but also anticipate the needs of
            tomorrow, ensuring a prosperous and sustainable future for
            generations to come.
          </p>
        </div>
      </div>
      {/* <div className="md:w-[70%] flex flex-wrap h-full gap-3 flex-col p-5 sm:text-sm text-xs text-justify font-normal px-10 ">
        <p
          data-aos="fade-right"
          data-aos-duration="1000"
          className="sm:text-2xl text-sm font-bold"
        >
          WHO WE ARE
        </p>
        <p data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200">
          Gas-Zone stands at the vanguard of Africa’s energy sector
          transformation, championing sustainability and innovation across the
          continent. Our mission is to deliver unparalleled solutions in energy
          advisory, trading, and infrastructure development, driven by a
          commitment to excellence and value for our clients and pa ners.
        </p>
        <p data-aos="fade-right" data-aos-duration="1000" data-aos-delay="400">
          At Gas-Zone, our team of seasoned professionals brings a wealth of
          experience and deep industry knowledge to the table. We pride
          ourselves on our ability to provide expe  guidance and suppo  across the
          entire energy value chain. Our services are designed to be
          comprehensive and tailored, ensuring that each client receives the
          precise assistance they need to meet their unique objectives and
          requirements.
        </p>
        <p
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="600"
          className="font-bold"
        >
          Our Commitment to Sustainability and Innovation{" "}
        </p>
        <p data-aos="fade-left" data-aos-duration="1000" data-aos-delay="800">
          Sustainability is at the core of our operations. We are constantly
          seeking new oppo unities and pa nerships that align with our vision of a
          sustainable future for Africa’s energy sector. Our innovative approach
          allows us to stay ahead of industry trends, ensuring that our
          solutions are not only relevant but also transformative.
        </p>
        <p data-aos="fade-left" data-aos-duration="1000">
          By leveraging our extensive industry knowledge and strategic insights,
          we are able to create impac ul solutions that address the dynamic needs
          of our clients and the broader market. Our commitment to
          sustainability and innovation drives us to continually explore new
          avenues for growth and development, fostering a resilient and
          forward-thinking energy industry in Africa.
        </p>
        <p data-aos="fade-left" data-aos-duration="1000">
          We are actively involved in research and development, investing in new
          technologies and methodologies that promote clean energy and reduce
          environmental impact. Our initiatives include exploring renewable
          energy sources, enhancing energy e ciency, and developing sma  grid
          technologies. By staying at the forefront of innovation, we aim to set
          new benchmarks in the energy sector and lead the way towards a greener
          future.
        </p>
        <p data-aos="fade-right" data-aos-duration="1000" className="font-bold">
          Our Vision for the Future{" "}
        </p>
        <p data-aos="fade-right" data-aos-duration="1000">
          At Gas-zone, we aspire to be a trusted pa ner and a driving force in
          shaping the future of Africa’s energy landscape. Our dedication to
          delivering excellence and value positions us as a leader in the
          industry, commi ed to making a lasting impact. We believe that through
          collaboration, innovation, and unwavering commitment to
          sustainability, we can help build a brighter, more sustainable future
          for all.
        </p>
        <p data-aos="fade-right" data-aos-duration="1000">
          Join us on this transformative journey as we pave the way for a new
          era of energy in Africa. Together, we can create solutions that not
          only meet the challenges of today but also anticipate the needs of
          tomorrow, ensuring a prosperous and sustainable future for generations
          to come.
        </p>
      </div> */}
      {/* <div className="p-0 w-[40%] hidden sm:block">
        <img src={rigImage} className="w-full h-[calc(100vh-90px)]" alt="Rig" />
      </div> */}
    </div>
  );
});
