import React from "react";

const rigImage = require("../assets/rig.jpg");

export const Home = () => {
  return (
    <div className="body-width flex sm:flex-row flex-col h-[100vh]">
      <div className="md:w-[45%] w-full sm:h-full h-[calc(100vh-80px)] bg-primary p-5 flex flex-col sm:justify-between  text-white gap-5">
        <div></div>
        <div className="font-bold text-center gap-10 h-full flex flex-col justify-center  ">
          <img
            src={require("../assets/rig-2.jpg")}
            className="sm:hidden flex w-full rounded"
            alt="Rig"
          />
          <p className=" hidden sm:flex sm:text-8xl text-4xl">
            Company Brochure
          </p>
          <p className=" sm:text-4xl text-2xl">
            Gas-Zone International Limited
          </p>
        </div>
        <div className="flex flex-col gap-5 p-5">
          <p className="underline">Strategic Partners</p>
          <div className="flex gap-5">
            <img
              src={require("../assets/logo-2.png")}
              className="sm:w-20 w-12  top-5 left-5"
            />
            <div>
              <p>TRANSOCEANIC</p>
              <p>Energy Group</p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[55%] md:flex hidden flex-wrap h-screen ">
        <img src={rigImage} className="w-1/2 h-1/2 object-cover" alt="Rig" />
        <img src={rigImage} className="w-1/2 h-1/2 object-cover" alt="Rig" />
        <img src={rigImage} className="w-1/2 h-1/2 object-cover" alt="Rig" />
        <img src={rigImage} className="w-1/2 h-1/2 object-cover" alt="Rig" />
      </div>
    </div>
  );
};
