import React, { forwardRef } from "react";

const rigImage = require("../assets/rig.jpg");
const energy = require("../assets/energy.jpg");

export const Services = forwardRef((props, ref) => {
  const expertise = [
    {
      title: "Asset Management",
      text: "End-to-end services and operational support throughout the entire lifecycle of an asset.",
    },
    {
      title: "Pipeline Solutions",
      text: "Specializing in pipeline design and construction, corrosion surveys, and protection systems.",
    },
    {
      title: "Energy Trading and Infrastructure Development",
      text: "Offering subsea installation, inspection, and maintenance services.",
    },
    {
      title: "Supply Services",
      text: "Robust trading capabilities and infrastructure solutions in LNG, NGLs, LPG, and crude oil.",
    },
    {
      title: "Reservoir Studies",
      text: "Providing compressors, industrial generators, pumps, OCTG pipes, meters, and essential equipment.",
    },
    {
      title: "Flare Gas Reduction",
      text: "Conducting detailed reservoir and production optimization studies.",
    },
    {
      title: "Asset Management",
      text: "Innovative solutions to reduce environmental impact",
    },
  ];
  const cardStyle =
    "p-5 bg-white rounded-md shadow-[3px_3px_5px_0.5px_rgba(0,0,0,0.3)] shadow-black hover:relative hover:top-[-5px] ";
  return (
    <div
      ref={ref}
      className="body-width flex flex-col md:h-[calc(100vh-65px)] p-10 gap-5 "
    >
      <p
        data-aos="fade-right"
        data-aos-duration="1000"
        className="sm:text-2xl text-sm font-bold text-white"
      >
        OUR EXPERTISE
      </p>
      {/* <div
        className="gap-5 md:w-2/3 rounded-2xl flex justify-center place-items-center md:h-full h-1/2 p-2 bg-primary"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.9)), url(${energy})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          alignSelf: "center",
        }}
      >
        <img src={energy} className="md:h-[500px] h-48 rounded" /> 
        <div
          className={"text-white"}
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <p className="font-bold sm:text-xl text-sm">Asset Management</p>
          <p>
            End-to-end services and operational support throughout the entire
            lifecycle of an asset.
          </p>
        </div>
      </div>*/}
      <div className="grid md:grid-cols-2 grid-cols-1 h-full gap-5">
        {expertise.map(({ title, text }) => (
          <div
            key={title}
            className={cardStyle}
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="font-bold sm:text-xl text-sm">{title}</p>
            <p>{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
});
